// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC5QK355uuknu0_ldVxFqqNqgp9oJi_eLc",
    authDomain: "ion4fullpwa.firebaseapp.com",
    databaseURL: "https://ion4fullpwa.firebaseio.com",
    projectId: "ion4fullpwa",
    storageBucket: "ion4fullpwa.appspot.com",
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500,
  },
  au: {
    serverUrl: "https://api.au.dev.getdqd.com",
  },
  eu: {
    serverUrl: "https://api.eu.dev.getdqd.com",
  },
  us: {
    serverUrl: "https://api.us.dev.getdqd.com",
  },
  treatUrl: "https://api.book.dev.getdqd.com",
  region: "ap-southeast-2",
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "ap-southeast-2_8UnjB1D9t",
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: "1hpga2e02taklgfcpphps2ts2l",

  stripePublishableKey: "pk_test_jWue6IQNAA9EUedGDgTAOgXM00XeE0W4wc",
  wordpress_api_url: "https://wordpress.startapplabs.com/blog/wp-json/wp/v2/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
