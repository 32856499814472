<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-icon
        class="center-icon ion-margin-bottom"
        name="business-outline"
        color="dark"
        size="large"
      ></ion-icon>
      <ion-card-title class="ion-text-center"
        >Booking Terms & Policies</ion-card-title
      >
      <ion-note class="ion-text-center"
        >The following govern the status and validity of your booking</ion-note
      >
    </ion-card-header>
    <ion-card-content
      ><ion-row>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="ticket-outline"></ion-icon
            ><ion-label
              >Your request will be attended within 7 days</ion-label
            ></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="enter-outline"></ion-icon
            ><ion-label
              >The Venue reserves the right to refuse entry</ion-label
            ></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="clipboard-outline"></ion-icon
            ><ion-label
              >The venue may request further information</ion-label
            ></ion-item
          >
        </ion-col>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="ribbon-outline"></ion-icon
            ><ion-label
              >This Booking is not a guarantee of entry</ion-label
            ></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="refresh"></ion-icon
            ><ion-label
              >Cancellation refunds are at venue discretion</ion-label
            ></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="pencil-outline"></ion-icon
            ><ion-label
              >The venue may change or alter your booking</ion-label
            ></ion-item
          >
        </ion-col>
      </ion-row></ion-card-content
    >
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-icon
        class="center-icon ion-margin-bottom"
        name="person-outline"
        color="dark"
        size="large"
      ></ion-icon>
      <ion-card-title class="ion-text-center"
        >Venue Privacy Policy</ion-card-title
      >
      <ion-note class="ion-text-center"
        >The venue may collect, use, and associate the following with your
        identity for marketing and operations.</ion-note
      >
    </ion-card-header>
    <ion-card-content
      ><ion-row>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="person-circle-outline"></ion-icon
            ><ion-label>Full Name</ion-label></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="call-outline"></ion-icon
            ><ion-label>Phone Number</ion-label></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="ticket-outline"></ion-icon
            ><ion-label>Booking Information</ion-label></ion-item
          >
        </ion-col>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="mail-unread-outline"></ion-icon
            ><ion-label>Email</ion-label></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="id-card-outline"></ion-icon
            ><ion-label>Gender</ion-label></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="wine-outline"></ion-icon
            ><ion-label>Venue Interactions</ion-label></ion-item
          >
        </ion-col>
      </ion-row>

      <ion-item lines="none" [style.margin-left.px]="5"
        ><ion-icon slot="start" name="mail-outline"></ion-icon
        ><ion-label
          >The venue may use your information to contact you for the purposes of
          managing this booking and marketing.</ion-label
        ></ion-item
      >
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      <ion-icon
        class="center-icon ion-margin-bottom"
        name="clipboard-outline"
        color="dark"
        size="large"
      ></ion-icon>
      <ion-card-title class="ion-text-center"
        >Platform Privacy Policy</ion-card-title
      >
      <ion-note class="ion-text-center"
        >This platform may collect, and aggregate the following to improve the
        services they provide</ion-note
      >
    </ion-card-header>
    <ion-card-content
      ><ion-row>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="id-card-outline"></ion-icon
            ><ion-label>Identifiers</ion-label></ion-item
          >
          <ion-item lines="none"
            ><ion-icon slot="start" name="bug-outline"></ion-icon
            ><ion-label>Usage Data</ion-label></ion-item
          >
        </ion-col>
        <ion-col [size]="6"
          ><ion-item lines="none"
            ><ion-icon slot="start" name="analytics-outline"></ion-icon
            ><ion-label>Diagnostics</ion-label></ion-item
          >
        </ion-col>
      </ion-row></ion-card-content
    >
  </ion-card>
  <div class="ion-margin-top ion-margin-bottom ion-padding-vertical"></div>
  <ion-card-title class="ion-text-center">Want more detail?</ion-card-title>
  <ion-note class="ion-margin-bottom ion-padding-bottom"
    >Read our extended policies below.</ion-note
  >
  <div class="c3 doc-content ion-text-center ion-margin-top ion-padding-top">
    <h3>DQ USER TERMS OF USE</h3>
    <h3>RESERVATIONS</h3>
    <p>1. INTRODUCTION</p>

    <p>
      Welcome to DQ, a cross-platform web application (the DQ App) designed to
      facilitate table service reservations, deposits, pre-orders and guestlists
      (the Services) at Venues.&#xa0; The DQ App is owned and operated by Queue
      Bar Pty ltd (ABN 69 169 609 232) (Queue Bar, We, Our and Us).&#xa0;
    </p>
    <p>&#xa0;</p>
    <p>
      By accessing, downloading or otherwise using the DQ App, You (the customer
      or User) (You Your and User(s)) agree to be bound by the following terms
      and conditions as an agreement binding upon You (Terms).
    </p>
    <p>&#xa0;</p>
    <p>
      Any additional terms, conditions, notices or disclaimers placed on the DQ
      App and/or Our Website from time to time which will be automatically
      incorporated into these Terms.
    </p>
    <p>&#xa0;</p>
    <p>
      If You do not agree with these Terms, You should stop using the DQ App
      immediately.
    </p>
    <p>&#xa0;</p>
    <p>
      Queue Bar reserves the right to amend, modify, add to or delete any of
      these Terms from time to time, and such modifications shall be effective
      immediately upon posting of the modified terms on the DQ App and/or Our
      Website.
    </p>
    <p>&#xa0;</p>
    <p>
      By continuing to use the DQ App after such modified terms have been posted
      on the DQ App and/or Our Website, You agree to be bound by these Terms as
      modified. All changes are effective when We post them and thereafter apply
      to Your access and use of the DQ App and the services using the DQ App.
    </p>
    <p>&#xa0;</p>
    <p>2. DEFINITIONS</p>
    <p>&#xa0;</p>
    <p>
      For the purposes of these Terms of Use, unless the context otherwise
      indicates, the definitions set out below apply.
    </p>
    <p>&#xa0;</p>
    <p>
      "the DQ App" means the cross-platform mobile applications known as DQ and
      includes
    </p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >the DQ Pro and Treat apps (The DQ Apps);</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">the Website;</span>
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >DQ Widgets embedded in Venue websites;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >the DQ API Interface, Servers and Databases</span
        >
      </li>
    </ul>
    <p style="margin-left: 18pt">&#xa0;</p>
    <p>
      "Terms &amp; Conditions" means the Queue Bar’s Terms &amp; Conditions as
      amended from time to time;
    </p>
    <p>&#xa0;</p>
    <p>
      "User" means a person accessing the DQ App to make a table reservation,
      join a guestlist or purchase products from a Venue.
    </p>
    <p>&#xa0;</p>
    <p>"Venue" means a venue that uses the DQ Apps;</p>
    <p>&#xa0;</p>
    <p>
      “Services” means table service reservations, deposits, pre-orders and
      guestlists provided by the Venue.
    </p>
    <p>&#xa0;</p>
    <p>"Website" means www.getdqd.com and related web pages.</p>
    <p>&#xa0;</p>
    <p>"You" or "Your" refers to the User of the DQ Apps.</p>
    <p>&#xa0;</p>
    <p>3. SERVICES</p>
    <p>&#xa0;</p>
    <p>
      The DQ App serves as a platform to facilitate the electronic reservation,
      deposit collection, and pre-order of items from Venues.
    </p>
    <p>&#xa0;</p>
    <p>
      When You use the DQ App to make a reservation with a Venue, You
      acknowledge that We have no control over and do not guarantee the quality,
      safety or legality of Services advertised by Venues on the DQ App, the
      truth or accuracy of Venues’ content or listings or that a Venue and User
      will actually complete a transaction or deliver a service.
    </p>
    <p>&#xa0;</p>
    <p>
      By making a reservation via the DQ App, You enter into a transaction and
      create a legally binding contract directly with a Venue.&#xa0; You must
      ensure that You comply with Your obligations to that Venue and are aware
      of any laws relevant to You as a buyer of the Service.&#xa0; If a Venue
      breaches any obligation to You, You – not Queue Bar – are solely
      responsible for enforcing any rights that You may have against the Venue.
    </p>
    <p>&#xa0;</p>
    <p>
      You alone, and not Queue Bar, are responsible for ensuring that any
      Service you purchase via the DQ App is lawful. You must ensure that You
      comply with all applicable laws.&#xa0; You must also ensure that You
      strictly comply with these Terms, the policies which form part of these
      Terms and any terms and conditions of sale communicated to You by a Venue.
    </p>
    <p>&#xa0;</p>
    <p>
      We do not take possession or ownership of any Services bought and sold via
      the DQ App at any time and do not transfer legal ownership of Services
      purchased from the Venue to Users.
    </p>
    <p>&#xa0;</p>
    <p>
      Queue Bar is not Your agent for any purpose in relation to these Terms or
      Your use of the DQ App.
    </p>
    <p>&#xa0;</p>
    <p>
      Queue Bar only acts as an agent for the Venue in displaying a Venue’s
      Services and facilitating the sale and purchase of Services from Venue.
    </p>
    <p>&#xa0;</p>
    <p>
      The DQ App will display details of the relevant Venue’s Services including
      the price, any applicable taxes and any rules, policies and terms and
      conditions of sale relating to those Service at the time of purchase
      (Venue Supply Terms).&#xa0; A Venue may change its prices and Venue Supply
      Terms at any time.
    </p>
    <p>&#xa0;</p>
    <p>
      Each Venue is the seller of the relevant Services and is solely
      responsible for fulfilling the supply of its Services purchased by You via
      the DQ App.
    </p>
    <p>&#xa0;</p>
    <p>4. USERS AND ACCOUNTS</p>
    <p>&#xa0;</p>
    <p>
      In order to use the DQ App, You may be required to provide Your details as
      a User with Queue Bar (DQ App Account). You confirm that the information
      You provide upon registration is current, complete and accurate at the
      time.&#xa0; Information provided by You will be used in accordance with
      Queue Bar’s privacy policy, which is set out Our Website (Privacy Policy).
    </p>
    <p>&#xa0;</p>
    <p>
      We reserve the right, without notice and in Our sole discretion at any
      time, to terminate Your right to access and use the DQ App or any
      component of it and to block or prevent future access to and use of the DQ
      App any related information. Any obligation or liability incurred prior to
      Our termination of Your access to the DQ App will survive such
      termination.
    </p>
    <p>&#xa0;</p>
    <p>5. PAYMENTS</p>
    <p>&#xa0;</p>
    <p>
      In order to make a reservation using the DQ App, You will need to use the
      booking widget, DQ app or follow the URL provided by The Venue; select the
      type of reservation You wish to purchase; and when prompted pay using a
      credit card, debit card or digital wallet (using the Gateway
      “Stripe”).&#xa0; Third party surcharges on Your method of payment may
      apply.
    </p>
    <p>&#xa0;</p>
    <p>
      The deposit amount payable will be held as a card authorisation until the
      earlier of the Venue accepting or declining Your booking, or seven days
      after the reservation when the authorised amount will be returned to Your
      method of payment.
    </p>
    <p>&#xa0;</p>
    <p>
      If You use the DQ app to purchase Services that include alcohol or from a
      Licenced Venue which has age restrictions, You warrant that You are above
      the age of eighteen (18) or, if outside Australia, You are of legal age in
      Your state and country of purchase and/or consume alcohol.
    </p>
    <p>&#xa0;</p>
    <p>
      Neither Queue Bar nor the Venue(s) have any liability to You in respect of
      any refusal on the part of a Venue to accept Your reservation request or
      to provide You with other Services. Any payment issues or disputes are
      between the User and the Venue, not Queue Bar.
    </p>
    <p>&#xa0;</p>
    <p>6.&#xa0; CHANGES, CANCELLATIONS &amp; REFUNDS</p>
    <p>&#xa0;</p>
    <p>
      You acknowledge that the Venue is entitled in its sole discretion to
      refuse to accept Your reservation or provide Services where to do so would
      be contrary to the Venue’s legal obligations, including those in respect
      of the responsible service of alcohol, or for any other reason.
    </p>
    <p>&#xa0;</p>
    <p>
      Where the Venue fails to accept, or rejects Your reservation within seven
      days of Your request, the authorisation on Your chosen payment method will
      be removed.
    </p>
    <p>&#xa0;</p>
    <p>
      Any changes, cancellations and refunds that Your request after the Venue
      accepts Your reservation are at the sole discretion of the Venue and You
      must contact the Venue directly.
    </p>
    <p>&#xa0;</p>
    <p>
      In the event that You or the Venue accepts and then cancels Services in
      compliance with these Terms, the Venue will issue a refund within a
      reasonable period of time. Queue Bar may asked to facilitate such
      refund.&#xa0; You will be notified via email or SMS of the cancellation.
    </p>
    <p>&#xa0;</p>
    <p>
      Neither Queue Bar nor a Venue bears any liability to You or obligation to
      provide a refund where You do are not permitted access the Venue because
      You fail to comply with these Terms or any other conditions of Entry as
      provided by the Venue.
    </p>
    <p>&#xa0;</p>
    <p>
      Neither Queue Bar nor the Venue(s) have any obligation to provide a refund
      for accidental, unintentional, incorrect or unauthorised use of the DQ App
      or Your DQ App Account (including as a result of a failure by You to keep
      secure Your username, password and/or Nominated Device) and/or operation
      of a Nominated Device which results in a purchase of Services expiring or
      becoming void prior to being redeemed.
    </p>
    <p>&#xa0;</p>
    <p>7. ADDITIONAL TERMS ON THIS DQ APP</p>
    <p>&#xa0;</p>
    <p>
      In addition to these Terms important notices may be posted on the DQ App
      and Our Website. Your use or ongoing use of the DQ App means that You also
      accept the terms, conditions, notices, disclaimers and policies posted on
      the DQ App or Our Website. Queue Bar reserves the right to modify these
      notices at any time.
    </p>
    <p>&#xa0;</p>
    <p>
      The additional terms include, but are not limited to any Disclaimer and
      Copyright, Cookies and Privacy Policy of Queue Bar.
    </p>
    <p>&#xa0;</p>
    <p>8. INTELLECTUAL PROPERTY</p>
    <p>&#xa0;</p>
    <p>
      All the material displayed on or available on the DQ App or Our Website,
      including content, data, information, tables, text, designs, graphics,
      layouts, names, logos, reports, articles, tools, email communications,
      source code and software, is owned or used under license by Queue Bar, and
      is protected by copyright, trademark and other intellectual property laws.
      You agree not to infringe any intellectual property rights owned by Queue
      Bar. Queue Bar reserves all of its rights under this clause.
    </p>
    <p>&#xa0;</p>
    <p>9. PERSONAL USE ONLY</p>
    <p>&#xa0;</p>
    <p>
      You agree that information contained on and services provided by the DQ
      App are intended for personal, non-commercial use only. The DQ App must
      not be used by You for any commercial purpose. You must not modify, copy,
      reproduce, publish, transmit, publicly display, create derivative works
      of, distribute or otherwise exploit in any way any material from or any
      part of the DQ App or Our Website including any code or software.
    </p>
    <p>&#xa0;</p>
    <p>
      You may not save or archive any significant portion of the material
      appearing in or on the DQ App or Our Website.&#xa0; All rights not
      expressly granted herein are reserved by Queue Bar.
    </p>
    <p>&#xa0;</p>
    <p>10. RESPONSIBLE USE OF THE DQ APP</p>
    <p>&#xa0;</p>
    <p>
      You may only use the DQ App for lawful purposes and in accordance with
      these Terms. You may not use the DQ App:
    </p>
    <p>&#xa0;</p>
    <p>a) for any purpose that is unlawful or prohibited by these Terms;</p>
    <p>b) to cause harm or damage to any person or entity;</p>
    <p>c) interfere with the proper operation of the DQ App; or</p>
    <p>
      d) to upload, post or transmit any material that violates any law,
      infringes on the rights of any third party or contains defamatory,
      libellous, abusive, obscene or otherwise objectionable material (as
      determined by Queue Bar in its sole discretion).
    </p>
    <p>&#xa0;</p>
    <p>
      You agree to defend, indemnify and hold harmless Queue Bar and its
      parents, affiliates, officers, directors, employees, franchisees, agents,
      licensors, business associates, and suppliers from and against any actual
      or threatened claims, actions or demands, liabilities and settlements
      (including, without limitation, reasonable legal and accounting fees)
      resulting (or alleged to result) from Your use of the DQ App in any manner
      that violates or is alleged to violate any applicable law or these Terms.
    </p>
    <p>&#xa0;</p>
    <p>11.NO RELIANCE</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar provides no guarantee or warranty to You that the services
      available through the DQ App will be free from variation, uninterrupted,
      error-free, secure, or that defects in the service will be
      corrected.&#xa0;
    </p>
    <p>&#xa0;</p>
    <p>
      Whilst Queue Bar will endeavour to maintain ongoing access, access to the
      DQ App may be suspended, restricted or terminated at any time. Queue Bar
      will not be liable for any software or hardware issues that may reside at
      Your end that limits or interferes with the services provided by Queue
      Bar.
    </p>
    <p>&#xa0;</p>
    <p>12. SOFTWARE LICENCE</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar grants You a non-exclusive, revocable, non-transferable licence
      to use its software on a server controlled by Queue Bar, for the sole
      purpose of accessing and obtaining information from the DQ App and/or Our
      Website pursuant to these Terms.
    </p>
    <p>&#xa0;</p>
    <p>13. THIRD PARTY LIABILITY</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar is not responsible for the content of any Venue(s) information,
      Venue Supply Terms, third party content, external webApps, menus,
      networks, advertisements, directories, software, servers, products and
      services, databases, information systems and the internet as a whole
      (Third Party Content) which You may access from the DQ App, Our Website or
      which is hyperlinked to the DQ App or Our Website from time to time.
    </p>
    <p>&#xa0;</p>
    <p>
      Links provided within the DQ App and Our Website may allow You to connect
      to other websites and services that are not under Queue Bar's
      control.&#xa0; We do not endorse and are not responsible for the content
      of such websites and/or services.&#xa0; You access such websites and use
      such services at Your own risk
    </p>
    <p>&#xa0;</p>
    <p>
      Queue Bar accepts no responsibility nor liability for promotions shown or
      advice given in Third Party Content.&#xa0; Queue Bar is not liable for any
      loss or damage incurred as a result of reliance by You on any Third Party
      Content.
    </p>
    <p>&#xa0;</p>
    <p>14. SECURITY OF INFORMATION</p>
    <p>&#xa0;</p>
    <p>
      While Queue Bar takes all due care in ensuring the privacy and integrity
      of the information You provide, Queue Bar cannot always ensure that its
      computer systems, network resources, files available for downloading and
      email communications will be fully or continually protected from unlawful
      access by others, including the infection of data or other information by
      viruses, or the alteration, misuse, or stealing of data or other
      information or that any of these activities will be detected. Should this
      occur, Queue Bar disclaims all liability to You to the full extent
      possible pursuant to law.
    </p>
    <p>&#xa0;</p>
    <p>15. PUSH NOTIFICATIONS , SMS &amp; EMAIL</p>
    <p>&#xa0;</p>
    <p>
      The DQ App includes push notifications, SMS, email or other mobile
      communication capability to confirm Your reservation and any changes to
      it. You hereby approve Our delivery of electronic communications directly
      to Your Nominated Device. These notifications, including badge, alert or
      pop-up messages, are delivered to Your Nominated Device even when it is
      running in the background. You have the ability, and it is Your
      responsibility, to control the notifications You do, or do not, receive
      through Your Nominated Device.
    </p>
    <p>&#xa0;</p>
    <p>16. LIMITATION OF LIABILITY</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar acknowledges that provisions in the Competition and Consumer Act
      2010 (Cth) (as amended) and other statutes from time to time in force
      imply or impose statutory guarantees, conditions or warranties into
      contracts for the supply of services which cannot be excluded, restricted
      or modified or can only be restricted or modified to a limited extent (Non
      Excludable Terms). Nothing in these Terms is intended to exclude or
      restrict the application of the Non Excludable Terms. Other than the Non
      Excludable Terms, Queue Bar hereby excludes all conditions, warranties,
      guarantees, terms and obligations expressed or implied by law in
      connection with these Terms, or any services provided under or pursuant to
      them, the DQ App and/or Our Website.
    </p>
    <p>&#xa0;</p>
    <p>
      Queue Bar is not liable to You or any other person for any loss or claim
      of any kind in connection with these Terms, the DQ App and/or Our Website
      or any services provided under any of them, except to the extent caused
      directly by the negligence or wilful misconduct of Queue Bar.
    </p>
    <p>&#xa0;</p>
    <p>
      If any Non Excludable Terms apply, then to the extent to which Queue Bar
      is entitled to do so, its liability under those Non Excludable Terms will
      be limited at its option to:
    </p>
    <p>&#xa0;</p>
    <p>a) the supplying of the services again; or</p>
    <p>
      b) the payment of the cost (if any) of having the services supplied again.
    </p>
    <p>&#xa0;</p>
    <p>
      To the extent permitted by law, Queue Bar will be under no liability to
      You or any third party in any circumstances for any indirect, special or
      consequential loss or damage, including but not limited to loss of revenue
      or loss of profit howsoever arising and whether in an action in contract,
      tort, in equity, under statute, or on any other basis.
    </p>
    <p>&#xa0;</p>
    <p>
      Notwithstanding anything to the contrary contained in these Terms, Queue
      Bar’s liability to You for any cause whatsoever and regardless of the form
      of the action, will at all times be limited to the amount paid, if any, by
      You to Queue Bar for Our services during the term of Your use of the DQ
      App.
    </p>
    <p>&#xa0;</p>
    <p>17. INDEMNITY</p>
    <p>&#xa0;</p>
    <p>
      To the extent permitted by law, You agree to indemnify Queue Bar against
      any claim, loss, liability or damage, costs, charges and expenses
      (including Queue Bar’s legal costs on an indemnity and/or solicitor/own
      client basis) suffered or incurred by Queue Bar which arise directly or
      indirectly in relation to Your use of the DQ App in violation of these
      Terms and/or arising from a breach of these Terms and/or any breach of
      Your representations and warranties set forth in these Terms.
    </p>
    <p>&#xa0;</p>
    <p>18.NOTICE</p>
    <p>&#xa0;</p>
    <p>
      You agree to promptly inform Queue Bar of any breach by You of these Terms
      and of any conduct of any other person which You think may be a breach of
      these Terms, whether the conduct has ceased, is continuing, or may occur
      in the future.
    </p>
    <p>&#xa0;</p>
    <p>19.PRIVACY</p>
    <p>&#xa0;</p>
    <p>
      The privacy and security of Your information is important to Queue Bar.
      Our Privacy Policy is incorporated into these Terms and also governs Your
      use of the DQ App. To the extent there is a conflict between the terms of
      the Privacy Policy and these Terms, these Terms govern.
    </p>
    <p>&#xa0;</p>
    <p>
      The Privacy Policy describes the data that We gather about or from Users
      of the DQ App and how We process, use and share that data. By using the DQ
      App, You consent to all actions that We take with respect to Your data
      consistent with Our Privacy Policy.
    </p>
    <p>&#xa0;</p>
    <p>Our Privacy Policy can be found here: getdqd.com/tos</p>
    <p>&#xa0;</p>
    <p>20. ACKNOWLEDGEMENT</p>
    <p>&#xa0;</p>
    <p>
      By accessing and/or using the DQ App or any of the services within the DQ
      App, You acknowledge that You have read and agree to be bound by these
      Terms.
    </p>
    <p>&#xa0;</p>
    <p>
      By use of this DQ App to purchase alcohol You acknowledge, warrant and
      agree that:
    </p>
    <p>&#xa0;</p>
    <p>
      a) You (and anyone for whom You are purchasing alcohol via the DQ App) are
      above the age of eighteen (18) or, if outside Australia, Your are of legal
      age to purchase and/or consume alcohol in your state and country;
    </p>
    <p>
      b) You satisfy all legal requirements for the purchase and/or consumption
      of alcohol in each State and Territory of Australia, or if outside
      Australia, Your state and country;
    </p>
    <p>
      c) You are the owner or User of the Nominated Device which You use to
      order the alcohol; and at no time while using the DQ App will You purchase
      alcohol drinks whilst intoxicated.
    </p>
    <p>&#xa0;</p>
    <p>21. FEES AND SERVICES</p>
    <p>&#xa0;</p>
    <p>We do not charge fees to Users for their use of the DQ App.</p>
    <p>&#xa0;</p>
    <p>
      When You use the DQ App to make an App Purchase from a Venue, You will
      have an opportunity to review and accept the fees that You will be charged
      by the Venue.
    </p>
    <p>&#xa0;</p>
    <p>
      Unless otherwise stated, all fees are quoted in Australian Dollars. You
      are responsible for paying all fees and applicable taxes associated with
      Your use of DQ App and any App Purchases made using the DQ App in a timely
      manner using one of the valid payment methods.
    </p>
    <p>&#xa0;</p>
    <p>22. ACCOUNT TERMINATION</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar reserves the right to disable or terminate access to the DQ App
      if it deems that the User is in breach of these Terms.
    </p>
    <p>&#xa0;</p>
    <p>23. GENERAL PROVISIONS</p>
    <p>&#xa0;</p>
    <p>
      These Terms are made in and governed by the laws of Victoria, Australia.
    </p>
    <p>&#xa0;</p>
    <p>
      Termination of these Terms will not end those provisions that are capable
      of surviving the ending of any agreement formed under or by these Terms.
    </p>
    <p>&#xa0;</p>
    <p>
      The failure of Queue Bar to exercise or enforce any right or provision in
      these Terms shall not operate as a waiver of such a right or provision.
    </p>
    <p>&#xa0;</p>
    <p>
      Should any part of these Terms be held to be void or unlawful, such part
      is to be read and enforced as if the void or unlawful part had been
      deleted.
    </p>
    <p>&#xa0;</p>
    <p>If You have any questions, please feel free to contact Us at:</p>
    <p>&#xa0;</p>
    <p>
      Email:&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      <a
        href="/cdn-cgi/l/email-protection"
        class="__cf_email__"
        data-cfemail="c3aba6afafac83a4a6b7a7b2a7eda0acae"
        >[email&#160;protected]</a
      >
    </p>
  </div>
  <div class="c3 doc-content ion-text-center ion-margin-top ion-padding-top">
    <h3>Queue Bar | End User Privacy Policy</h3>
    <p>1. ABOUT THIS POLICY</p>
    <p>
      Queue Bar Pty Ltd (Queue Bar) is committed to protecting your privacy and
      complying with the General Data Protection Regulation (GDPR) and
      Singapore's Personal Data Protection Act (PDPA). This Privacy Policy sets
      out how Queue Bar collects, uses, and discloses information about its
      users in accordance with these regulations.
    </p>
    <p>&#xa0;</p>
    <p>
      By accessing or using the DQ System (the System), you agree to the
      collection, use, and disclosure of information in the manner set out in
      this Policy.
    </p>
    <p>&#xa0;</p>
    <p>
      We recommend that you carefully read this Policy before you use or submit
      information to the System.
    </p>
    <p>&#xa0;</p>
    <p>
      We may change this Policy at any time. You should regularly check our
      website for changes to the Policy.
    </p>
    <p>&#xa0;</p>
    <p>
      You should not use or submit personal or Venue information to the System
      if you do not agree to this Policy.
    </p>
    <p>&#xa0;</p>
    <p>2. DEFINITIONS</p>
    <p>&#xa0;</p>
    <p>
      For the purposes of this Policy, unless the context otherwise indicates,
      the definitions set out below and in Queue Bar’s Terms &amp; Conditions
      apply to this Policy.
    </p>
    <p>&#xa0;</p>
    <p>
      "DQ System" means the cross-platform mobile applications known as DQ and
      includes
    </p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">the API Interface</span>
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >the DQ Pro and Treat apps (The DQ Apps)</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">The DQ Server;</span>
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">The DQ Database;</span>
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">the Website; </span>
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri">the DQ Venue Web Portal;</span>
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>
      "Terms &amp; Conditions" means Queue Bar’s Terms &amp; Conditions as
      amended from time to time;
    </p>
    <p>&#xa0;</p>
    <p>
      "User" means a person accessing the DQ System to make a table reservation,
      join a guestlist or purchase products rom a Venue.
    </p>
    <p>&#xa0;</p>
    <p>"Venue" means a venue that uses the DQ System and DQ Apps;</p>
    <p>&#xa0;</p>
    <p>
      "Website" means
      <a href="http://www.getdqd.com" style="text-decoration: none"
        ><span class="Hyperlink">www.getdqd.com</span></a
      >
      and related web pages.
    </p>
    <p>&#xa0;</p>
    <p>"You" or "your" refers to the User of the DQ System.</p>
    <p>&#xa0;</p>
    <p>3. PERSONAL DATA</p>
    <p>&#xa0;</p>
    <p>
      In order to offer the services it provides, Queue Bar may collect
      information about its Users and the use of the System.
    </p>
    <p>&#xa0;</p>
    <p>
      Personal information collected by Queue Bar may include the name, email
      address, contact phone number of a User;
    </p>
    <p>&#xa0;</p>
    <p>Other information collected by Queue Bar may include:</p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >the services provided to you via the DQ System;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >Menu items and prices uploaded onto the DQ System; and</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >records of orders, transactions, and communications between Queue
          Bar, Users, Venues, and/or third parties who supply Products and
          Services to you via your use of the System.</span
        >
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>
      If you choose not to provide Queue Bar with certain information, Queue Bar
      may not be able to provide you with the services you require or those
      offered by the use of the System.
    </p>
    <p>&#xa0;</p>
    <p>4. COLLECTION OF YOUR INFORMATION</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar collects personal information about Users when they provide this
      information directly via data submitted into the DQ Apps;
    </p>
    <p>&#xa0;</p>
    <p>Queue Bar collects business information:</p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >when the User or Venue provides the information directly to Queue Bar
          in person, by phone or in writing, including forms and data submitted
          via the System; and</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >by recording User interactions, transactions, and other communication
          data passed between components of the System (including, but not
          limited to, the DQ Apps, DQ Server, and DQ Database)</span
        >
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>5. USE OF YOUR INFORMATION</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar may use the information it collects for the following purposes:
    </p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >to provide you with the services you request via your use of the DQ
          System;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >to administer and manage those services, including charging and
          billing;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >to research, develop, and improve the DQ System and other services
          provided by Queue Bar or proposed to be provided by Queue Bar;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >to verify information you provide, including your identity;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >to aggregate and package non-personal and unidentifiable transaction,
          demographic, and behaviour data for disclosure to venues, suppliers,
          and other third parties with whom we have commercial relationships,
          for business, marketing, and related purposes;</span
        >
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>6. DISCLOSURE OF YOUR INFORMATION BY QUEUE BAR</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar may disclose your information to other individuals and
      organizations for the purposes of providing you with the services
      requested via your use of the System, including:
    </p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >Employees or representatives of the Venue where you receive products
          and/or services associated with your use of the DQ System;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >Queue Bar’s officers, employees, contractors, and agents;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >service providers engaged by Queue Bar to assist it in providing the
          services, information technology providers, marketing market research
          advisers, professional advisers, suppliers, and other third parties
          with whom we have commercial relationships, for business, marketing,
          and related purposes;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >organizations involved in a sale or transfer of Queue Bar’s assets,
          business, or shares; and</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >government and statutory authorities as provided by law.</span
        >
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>7. INFORMATION YOU DISCLOSE TO OTHERS</p>
    <p>&#xa0;</p>
    <p>
      Some features of the DQ App allow you to share some information with
      others via third-party websites and apps using a unique URL created by
      you;
    </p>
    <p>&#xa0;</p>
    <p>This information may include:</p>
    <ul style="margin: 0pt; padding-left: 0pt">
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >A list of products available for purchase at the licensed premises
          you are attending;</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >Your name and/or a profile photo uploads by you; and</span
        >
      </li>
      <li
        class="ListParagraph"
        style="margin-left: 28.52pt; padding-left: 7.48pt; font-family: serif"
      >
        <span style="font-family: Calibri"
          >Any other information you elect to share;</span
        >
      </li>
    </ul>
    <p>&#xa0;</p>
    <p>
      You may also opt in for some information to be shared by the venue on a
      public website.
    </p>
    <p>&#xa0;</p>
    <p>
      If you opt in to your information being shared on a public website or
      choose to share your information with third parties, you are doing so at
      your own discretion.
    </p>
    <p>&#xa0;</p>
    <p>8. WITHDRAWING YOUR CONSENT</p>
    <p>&#xa0;</p>
    <p>
      The consent that you provide for the collection, use and disclosure of
      your personal data will remain valid until such time it is being withdrawn
      by you in writing. You may withdraw consent and request us to stop using
      and/or disclosing your personal data for any or all of the purposes listed
      above by submitting your request in writing or via email to our Data
      Protection Officer at the contact details provided below.
    </p>
    <p>&#xa0;</p>
    <p>
      Upon receipt of your written request to withdraw your consent, we may
      require reasonable time (depending on the complexity of the request and
      its impact on our relationship with you) for your request to be processed
      and for us to notify you of the consequences of us acceding to the same,
      including any legal consequences which may affect your rights and
      liabilities to us. In general, we shall seek to process your request
      within ten (10) business days of receiving it.
    </p>
    <p>&#xa0;</p>
    <p>
      Whilst we respect your decision to withdraw your consent, please note that
      depending on the nature and scope of your request, we may not be in a
      position to continue providing our goods or services to you and we shall,
      in such circumstances, notify you before completing the processing of your
      request. Should you decide to cancel your withdrawal of consent, please
      inform us in writing in the manner described in clause 8 above.
    </p>
    <p>&#xa0;</p>
    <p>
      Please note that withdrawing consent does not affect our right to continue
      to collect, use and disclose personal data where such collection, use and
      disclose without consent is permitted or required under applicable laws.
    </p>
    <p>&#xa0;</p>
    <p>9. ACCESS TO INFORMATION AND ACCURACY</p>
    <p>&#xa0;</p>
    <p>
      You have a right to access your information held by Queue Bar relating to
      your Venue or Guests and to correct any information that is out-of-date or
      incorrect.
    </p>
    <p>&#xa0;</p>
    <p>
      If you wish to access, update, correct or know more about the storage or
      collection of your information, please contact Queue Bar using the email
      address below.&#xa0; We will respond to your request as soon as reasonably
      possible.
    </p>
    <p>&#xa0;</p>
    <p>
      Should we not be able to respond to your request within thirty (30) days
      after receiving your request, we will inform you in writing within thirty
      (30) days of the time by which we will be able to respond to your request.
      If we are unable to provide you with any personal data or to make a
      correction requested by you, we shall generally inform you of the reasons
      why we are unable to do so (except where we are not required to do so
      under the PDPA).
    </p>
    <p>&#xa0;</p>
    <p>10. USERS IN SINGAPORE</p>
    <p>&#xa0;</p>
    <p>
      Queue Bar stores personal data on Amazon Web Services servers and database
      instances which may be located outside of Singapore. Transfers of data
      collected from Users in Singapore shall be conducted in compliance with
      the requirements of the PDPA.
    </p>
    <p>&#xa0;</p>
    <p>
      We generally do not transfer your personal data to countries outside of
      Singapore for any other purpose. If we do so, we will obtain your consent
      for the transfer to be made and we will take steps to ensure that your
      personal data continues to receive a standard of protection that is at
      least comparable to that provided under the PDPA.
    </p>
    <p>&#xa0;</p>
    <p>11. ANONYMOUS INFORMATION AND COOKIES</p>
    <p>&#xa0;</p>
    <p>
      When you use the DQ System, Queue Bar may record anonymous information
      such as your IP address, your unique device identifier, and the time and
      date you accessed the DQ System.
    </p>
    <p>&#xa0;</p>
    <p>
      Cookies may be used by Queue Bar to enable a User to be provided with the
      full range of services offered by the DQ System, to remember the User’s
      preferences, to measure and analyse usage of the DQ System, and for
      security.
    </p>
    <p>&#xa0;</p>
    <p>
      Cookies may also collect and store your personal information. This Policy
      applies to information collected in this way.
    </p>
    <p>&#xa0;</p>
    <p>
      You may adjust your Internet browser and or downloaded DQ Apps to disable
      cookies; however, this may result in you not being able to access the full
      range of the DQ System’s services.
    </p>
    <p>&#xa0;</p>
    <p>12. SECURITY</p>
    <p>&#xa0;</p>
    <p>
      Personal information collected by Queue Bar is stored securely to
      reasonably protect you from the misuse, loss, and unauthorized access,
      modifications, or disclosure of your personal information.
    </p>
    <p>&#xa0;</p>
    <p>
      You should be aware, however, that no method of transmission over the
      Internet or method of electronic storage is completely secure. While
      security cannot be guaranteed, we strive to protect the security of your
      information and are constantly reviewing and enhancing our information
      security measures.
    </p>
    <p>&#xa0;</p>
    <p>13. CONTACT DETAILS</p>
    <p>&#xa0;</p>
    <p>
      If you would like more information about Queue Bar’s Privacy Policy or if
      you would like to make a complaint or access or change your personal
      information, please contact Queue Bar.
    </p>
    <p>&#xa0;</p>
    <p>
      This amended Privacy Policy aligns with GDPR and Singapore's PDPA and
      should be reviewed and implemented accordingly.
    </p>
    <p>&#xa0;</p>
    <p>The Queue Bar Privacy Officer</p>
    <p>
      <a
        href="/cdn-cgi/l/email-protection#3444465d4255574d745351405045501a575b59"
        style="text-decoration: none"
        ><span class="Hyperlink"
          ><span
            class="__cf_email__"
            data-cfemail="87f7f5eef1e6e4fec7e0e2f3e3f6e3a9e4e8ea"
            >[email&#160;protected]</span
          ></span
        ></a
      >
    </p>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>&#xa0;</p>
    <p>Version 2.0</p>
    <p>Last modified: 22 November 2023</p>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" (click)="close()">Close</ion-button>
  </ion-toolbar>
</ion-footer>
