import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  constructor(private activeroute: ActivatedRoute) {}

  getRegion() {
    if (window.location.href.includes("/au/")) {
      return "au";
    } else if (window.location.href.includes("/eu/")) {
      return "eu";
    } else if (window.location.href.includes("/us/")) {
      return "us";
    }

    return "";
  }

  ServerURL(reg?: string) {
    if (reg != undefined) {
      return environment[reg].serverUrl;
    } else {
      console.log(this.getRegion());
      return environment[this.getRegion()].serverUrl;
    }
  }
}
