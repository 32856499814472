<ion-footer keyboard-attach>
  <div class="full-height" [style.margin-bottom.px]="50">
    <div class="loadingspinner" *ngIf="loading">
      <ion-item lines="none">
        <ion-spinner color="dark" slot="start"></ion-spinner>
        <h4>Loading</h4></ion-item
      >
    </div>
    <div class="ion-margin-horizontal" *ngIf="collect === true">
      <h2 class="ion-text-center ion-padding-bottom ion-margin-bottom">
        You need to verify your number to see this page
      </h2>
      <ion-item lines="none">
        <ion-label class="ion-margin-start" position="stacked">Phone</ion-label>
        <ion-row [style.width.%]="100">
          <ion-select
            [required]="true"
            class="borderleft"
            [(ngModel)]="DiallingCode"
          >
            <ion-select-option
              *ngFor="let dialingCode of dialingCodes"
              [value]="dialingCode.dial_code"
            >
              {{ dialingCode.dial_code }} &nbsp; &nbsp; &nbsp;
              {{ dialingCode.name }}
            </ion-select-option>
          </ion-select>
          <ion-input
            [style.width.%]="100"
            class="inputselect"
            [required]="true"
            [type]="isTablet ? 'tel' : 'number'"
            placeholder="0000 000 000"
            [(ngModel)]="InputPhone"
          >
          </ion-input>
        </ion-row>
      </ion-item>
    </div>
    <div
      *ngIf="loading === false && collect === false"
      class="ion-margin-horizontal"
    >
      <h2 class="ion-text-center ion-padding-bottom ion-margin-bottom">
        Enter the pin code sent to your mobile
      </h2>
      <ng-otp-input
        (onInputChange)="finalisecode($event)"
        [config]="config"
      ></ng-otp-input>
    </div>
  </div>
  <ion-toolbar
    [style.padding-top.px]="0"
    *ngIf="collect"
    class="mobilewidth"
    [style.max-height.px]="60"
  >
    <ion-item lines="none">
      <ion-label class="minmarg rowfl ion-text-wrap" [style.font-size.px]="16"
        >Accept Our Privacy & Marketing Policy<ion-button
          (click)="viewterms()"
          [style.margin-left.px]="5"
          >View</ion-button
        ></ion-label
      >

      <ion-checkbox
        [(ngModel)]="approvedTC"
        class="minmarg"
        slot="end"
      ></ion-checkbox>
    </ion-item>
  </ion-toolbar>
  <ion-toolbar *ngIf="collect">
    <ion-button
      expand="block"
      [disabled]="approvedTC === false || InputPhone.length < 4"
      (click)="SendCode()"
      >Send Code</ion-button
    >
  </ion-toolbar>
  <ion-toolbar *ngIf="!collect && ChangeNumber">
    <ion-button expand="block">Back</ion-button>
  </ion-toolbar>
</ion-footer>

<ion-footer *ngIf="!ChangeNumber">
  <ion-toolbar *ngIf="!loading">
    <ion-button (click)="cancel()" expand="block">Back</ion-button>
  </ion-toolbar>
</ion-footer>
