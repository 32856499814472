import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { BookingService } from "../../services/booking/booking.service";
import { take } from "rxjs";
import { updatePhoneNumber } from "firebase/auth";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { RegionsService } from "../../services/regions/regions.service";
import dials from "../../../assets/dialing.json";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { TermsModalComponent } from "../terms/terms.component";

@Component({
  selector: "app-auth-modal",
  templateUrl: "./auth-modal.component.html",
  styleUrls: ["./auth-modal.component.scss"],
})
export class AuthModalComponent implements OnInit {
  @Input() PhoneNum: string;
  @Input() ChangeNumber?: boolean = false;

  InputPhone = "";
  DiallingCode = "";
  dialingCodes = dials;
  loading = false;
  collect = false;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
      "background-color": "transparent",
      border: "2px solid var(--ion-color-primary)",
      "border-radius": "10px",
    },
    containerStyles: {
      width: "100%",
      display: "flex",
      "flex-direction": "row",

      "justify-content": "space-between",
    },
  };

  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private nav: NavController,
    private active: ActivatedRoute,
    private http: HttpClient,
    private BookingSvc: BookingService,
    private region: RegionsService,
    private analy: AnalyticsService
  ) {}

  async ngOnInit() {
    if (this.ChangeNumber == true) {
      this.ChangeNumber = true;
      this.collect = true;

      switch (this.region.getRegion()) {
        case "au":
          this.DiallingCode = "+61";
          break;
        case "us":
          this.DiallingCode = "+1";
          break;
        case "eu":
          this.DiallingCode = "+44";
          break;
        default:
          break;
      }
    } else {
      this.collect = false;
      await this.SendCode();
    }
  }

  async SendCode() {
    this.collect = false;
    let temp = this.PhoneNum;
    this.loading = true;
    console.log(temp);

    if (this.ChangeNumber) {
      //remove the 0 fromn the start of the number if it exists
      if (this.InputPhone.charAt(0) == "0") {
        this.InputPhone = this.InputPhone.substring(1);
      }
      temp = this.DiallingCode + this.InputPhone;
    }
    try {
      this.user = await Auth.signIn(temp);
      console.log(this.user);
    } catch (e) {
      console.log(e);
      try {
        const params = {
          username: temp,
          password: this.randomString(
            40,
            "mkeroi321phje9u0231uhe9r3u1284097832u10hre382091jhec30v"
          ),
          attributes: {
            name: temp,
            email: "hello@getdqd.com",
            phone_number: temp,
          },
        };
        var value = await Auth.signUp(params);
        console.log("signup!");
        console.log(value);
        this.user = await Auth.signIn(temp);
        console.log("signin!");
        console.log(this.user);
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.toast
          .create({
            message: e.message,
            duration: 3000,
            position: "top",
          })
          .then((res) => {
            res.present();
          });

        this.loading = false;
        if (this.ChangeNumber == true) {
          this.collect = true;
        }
      }
    }
    this.loading = false;
    return true;
  }

  randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  user;
  approvedTC = false;
  async finalisecode(event) {
    if (event.length != 6) {
      return;
    }
    this.loading = true;
    //await Auth.answerCustomChallenge(event);
    console.log(this.user);
    console.log(event);
    try {
      await Amplify.Auth.sendCustomChallengeAnswer(this.user, event.toString());
    } catch (e) {
      console.log(e);
      this.toast
        .create({
          header: e.message,
          message: "Please try again, we've resent the code",
          duration: 3000,
          position: "top",
        })
        .then((res) => {
          res.present();
        });
      this.loading = false;
      await this.SendCode();
      return;
    }
    console.log(event);

    //wait for 1 second before continueing

    await new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      this.analy.identifyUser(this.user);
    } catch (e) {
      console.log(e);
    }
    this.modalController.dismiss(this.user);
  }

  cancel() {
    this.modalController.dismiss();
  }

  viewterms() {
    //get app-booking-checkout for presenting element via document
    this.modalController
      .create({
        presentingElement: document.getElementById("app-booking-checkout"),
        component: TermsModalComponent,
      })
      .then((res) => {
        res.present();
      });
  }
}
