import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RegionsService } from "../regions/regions.service";
import {
  AvailabilityList,
  BookingCheckout,
  BookingGuest,
  BookingList,
  BookingManagment,
  EventList,
  GuestList,
  GuestStats,
  OrderRequest,
  Event,
} from "../../models/bookings";
import { CardList } from "../../models/bump_screen_models";

@Injectable({ providedIn: "root" })
export class BookingService {
  constructor(private http: HttpClient, private reg: RegionsService) {}


  getCachedObject(key: string) {
    //get the object from the local cache
    const cachedObject = localStorage.getItem(key);
    if (cachedObject) {
      return JSON.parse(cachedObject);
    }
    return undefined;
  }

  cacheObject(key: string, object: any) {
    //cache the object
    localStorage.setItem(key, JSON.stringify(object));
  }

  getActiveEventId() {
    return this.http.get<{ e_id: string | null; r_id: number | null }>(
      `${this.reg.ServerURL()}/event/current_id`
    );
  }

  updateTreatUserDetails(user) {
    return this.http.post(`${environment.treatUrl}/profile`, user);
  }
  getTreatUserDetails() {
    return this.http.get(`${environment.treatUrl}/profile`);
  }

  getMap(venue_id) {
    return this.http.get(`${this.reg.ServerURL()}/v4/venue/map`, {
      params: {
        venue_id: venue_id,
      },
    });
  }

  CreateNewBooking(booking) {
    return this.http.post(`${this.reg.ServerURL()}/bookings/new`, booking);
  }

  GetWalletKit(booking) {
    return this.http.get(`${this.reg.ServerURL()}/walletkit/` + booking);
  }
  getGuestList(guest_id: string) {
    return this.http.get(`${this.reg.ServerURL()}/guest_list/${guest_id}`);
  }
  getGuestListEntry(guest_id: string) {
    return this.http.get(`${this.reg.ServerURL()}/guest_entry/${guest_id}`);
  }

  getGuestListEntryAuth(guest_id: string) {
    return this.http.get(
      `${this.reg.ServerURL()}/bookings/guest_entry/${guest_id}`
    );
  }

  addToGuestList(guest, booking_public_id, private_id = "") {
    return this.http.post(
      `${this.reg.ServerURL()}/guest_list/${booking_public_id}`,
      guest,
      { params: { private_id: private_id } }
    );
  }

  deleteGuestList(guest, booking_id) {
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/delete`,
      guest
    );
  }

  postItemRequest(booking, items) {
    let body: OrderRequest = {
      orders: items,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking}/request`,
      body
    );
  }

  //get venues events
  getVenuesEvents(venue_id: string, operational = false) {
    return this.http.get<EventList>(
      `${this.reg.ServerURL()}/bookings/${venue_id}/events`,
      {
        params: {
          operational: operational.toString(),
        },
      }
    );
  }

  getVenueBranding(venue_id: string) {
    return this.http.get(
      `${this.reg.ServerURL()}/v4/venue/${venue_id}/branding`
    );
  }

  getBookingOrders(booking_id: string) {
    return this.http.get<CardList>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/orders`
    );
  }

  getGuestStats(guest_id: string) {
    return this.http.get<GuestStats>(
      `${this.reg.ServerURL()}/bookings/${guest_id}/stats`
    );
  }
  getPromoterEvents() {
    return this.http.get<EventList>(
      `${this.reg.ServerURL()}/bookings/promoter`
    );
  }

  getPromoterCode() {
    return this.http.get<any>(`${this.reg.ServerURL()}/bookings/refcode`);
  }

  updatePromoterCode(body) {
    return this.http.post<any>(
      `${this.reg.ServerURL()}/bookings/refcode`,
      body
    );
  }

  getEvent(e_id: string) {
    return this.http.get<Event>(
      `${this.reg.ServerURL()}/bookings/events/${e_id}`
    );
  }

  getEventAvailability(e_id: string) {
    return this.http.get<AvailabilityList>(
      `${this.reg.ServerURL()}/bookings/events/${e_id}/availability`
    );
  }

  createBooking(booking) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/events/${booking.event_id}`,
      booking
    );
  }

  AddListGuest(booking, guest_list) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/${booking}/guests`,
      guest_list
    );
  }

  MergeGuests(old_guest_id, new_guest_id) {
    return this.http.post<{ old_guest_id: string; new_guest_id: string }>(
      `${this.reg.ServerURL()}/bookings/guests/merge`,
      {
        old_guest_id: old_guest_id,
        new_guest_id: new_guest_id,
      }
    );
  }
  getWalletCard(booking_id) {
    return this.http.get(`${this.reg.ServerURL()}/passkit/` + booking_id);
  }

  RemoveListGuest(booking, guest_id) {
    return this.http.delete(
      `${this.reg.ServerURL()}/bookings/${booking}/guests/${guest_id}`
    );
  }
  UpdateListGuest(booking, guest_list) {
    return this.http.post<{ booking_id: string }>(
      `${this.reg.ServerURL()}/bookings/${booking}/guests/${guest_list.id}`,
      guest_list
    );
  }

  readyCheckout(data, booking_id) {
    return this.http.post<BookingCheckout>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkout`,
      data
    );
  }

  uploadAvatar(avatar) {
    return this.http.post(`${this.reg.ServerURL()}/users/avatar`, avatar);
  }

  updateTreatLink(data, gift) {
    return this.http.post<BookingCheckout>(
      `${this.reg.ServerURL()}/bookings/${gift}/gift`,
      data
    );
  }
  getTreatLink(booking) {
    return this.http.get(`${this.reg.ServerURL()}/bookings/${booking}/gift`);
  }

  getProfile() {
    return this.http.get(environment.treatUrl + "/profile");
  }
  updateProfile(body) {
    return this.http.post(environment.treatUrl + "/profile", body);
  }

  getBookings(operational = false, e_id?, status?, guest_lists = false) {
    let params = {
      operational: operational.toString(),
    };
    if (e_id) {
      params["e_id"] = e_id;
    }
    if (status) {
      params["status"] = status;
    }
    if (guest_lists) {
      params["guest_lists"] = true;
    }
    return this.http.get<BookingList>(`${this.reg.ServerURL()}/bookings`, {
      params: params,
    });
  }

  getBooking(booking_id, checkin = false) {
    let params = {};
    if (checkin) {
      params = {
        checkin: checkin.toString(),
      };
    }

    return this.http.get<BookingManagment>(
      `${this.reg.ServerURL()}/bookings/${booking_id}`,
      {
        params: params,
      }
    );
  }

  getInstagram(username) {
    return this.http.get(
      `${this.reg.ServerURL()}/instagram_scrape/${username}`
    );
  }

  changeBookingStatus(booking_id, status) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/status_transition`,
      body
    );
  }
  updateBooking(booking) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking.id}`,
      booking
    );
  }

  updateGuest(guest) {
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/guest/${guest.id}`,
      guest
    );
  }
  createGuest(guest) {
    return this.http.post<BookingGuest>(
      `${this.reg.ServerURL()}/bookings/guest`,
      guest
    );
  }

  checkin(items, booking_id) {
    let body = {
      status: status,
    };
    return this.http.post(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkin`,
      items
    );
  }

  getBookingCheckout(booking_id) {
    return this.http.get<BookingCheckout>(
      `${this.reg.ServerURL()}/bookings/${booking_id}/checkout`
    );
  }
}
