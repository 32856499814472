import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RegionsService } from "../regions/regions.service";
import {
  AvailabilityList,
  BookingCheckout,
  BookingGuest,
  BookingList,
  BookingManagment,
  EventList,
  GuestList,
  GuestStats,
  OrderRequest,
  Event,
} from "../../models/bookings";
import { CardList } from "../../models/bump_screen_models";
import { updatePhoneNumber } from "firebase/auth";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { ModalController, ToastController } from "@ionic/angular";
import { AuthModalComponent } from "../../components/auth-modal/auth-modal.component";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(
    private reg: RegionsService,
    private modal: ModalController,
    private toast: ToastController
  ) {}

  //this will show the modal for entering your phone number ect
  async RequireAuth(phonenum?: string) {
    let loggedin = false;
    let user;
    try {
      let us = await Auth.currentUserPoolUser();
      console.log(us);
      if (us) {
        loggedin = true;
        user = us;
      }
    } catch (e) {
      console.log(e);
    }
    if (!loggedin) {
      await this.AuthModal(phonenum);
      user = await Auth.currentUserPoolUser();
    }

    console.log("successfully authenticated");
    return true;
  }

  logout() {
    Auth.signOut();
  }

  async AuthModal(phonenum?: string) {
    //show modal
    //if no phone num collect it
    //if phone num skip straight to waiting for code
    //wait for code
    //once done close modal with user object and then return user object
    //if user object returned is null invoke this function again
    //get topmost page using document queries
    let config = {
      presentingElement: undefined,
      component: AuthModalComponent,
      cssClass: "authmodal",
      componentProps: {
        PhoneNum: phonenum,
        ChangeNumber: false,
      },
      showBackdrop: true,
      backdropDismiss: false,
    };
    let page;
    try {
      page = document.querySelector("ion-router-outlet").lastElementChild;
    } catch (e) {
      console.log("no page found");
      config.cssClass = "authiddle";
    }
    if (page != undefined) {
      config.presentingElement = page;
    }

    if (phonenum == undefined || phonenum == "") {
      config.componentProps.ChangeNumber = true;
      config.backdropDismiss = true;
    }
    //create a modal and wait for it to close
    let modal = await this.modal.create(config);
    await modal.present();
    let { data } = await modal.onWillDismiss();
    console.log(data);
    if (data) {
      let us = await Auth.currentUserPoolUser();

      return data;
    } else {
      throw new Error("User did not authenticate");
    }
  }
}
