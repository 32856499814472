import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { ShellModule } from "../shell/shell.module";

import { CheckboxWrapperComponent } from "./checkbox-wrapper/checkbox-wrapper.component";
import { ShowHidePasswordComponent } from "./show-hide-password/show-hide-password.component";
import { CountdownTimerComponent } from "./countdown-timer/countdown-timer.component";
import { CounterInputComponent } from "./counter-input/counter-input.component";
import { RatingInputComponent } from "./rating-input/rating-input.component";
import { GoogleMapComponent } from "./google-map/google-map.component";
import { EventCardBigComponent } from "./event-card-big/event-card.component";
import { PipesModule } from "../pipes/pipes.module";
import { EventCardComponent } from "./event-card/event-card.component";
import { PackageCardComponent } from "./package-card/package-card.component";
import { HeaderComponent } from "./header/event-card.component";
import { TreatRequestComponent } from "./treat-request/treat-request.component";
import { AuthModalComponent } from "./auth-modal/auth-modal.component";
import { NgOtpInputModule } from "ng-otp-input";
import { AuthRetryComponent } from "./aunauth/auth-modal.component";
import { TermsModalComponent } from "./terms/terms.component";
import { MapComponent } from "./map/map.component"; // "../../../../../frontend/src/app/components/map/map.component" // ./map/map.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    ShellModule,
    IonicModule,
    PipesModule,
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    EventCardBigComponent,
    EventCardComponent,
    PackageCardComponent,
    HeaderComponent,
    TreatRequestComponent,
    TermsModalComponent,
    AuthModalComponent,
    MapComponent,
    AuthRetryComponent,
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    EventCardBigComponent,
    TermsModalComponent,
    EventCardComponent,
    PackageCardComponent,
    HeaderComponent,
    TreatRequestComponent,
    AuthModalComponent,
    MapComponent,
    AuthRetryComponent,
  ],
})
export class ComponentsModule {}
