import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import "@aws-amplify/api-graphql";
import "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { Capacitor } from "@capacitor/core";
// initialize stripe https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
import "@stripe/stripe-js";
import awsConfig from "./aws-exports";

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
  },
});

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
