import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RegionsService } from "../regions/regions.service";
import { Capacitor } from "@capacitor/core";
import mixpanel from "mixpanel-browser";
import Auth, { CognitoUser } from "@aws-amplify/auth";

import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
@Injectable({ providedIn: "root" })
export class AnalyticsService {
  client;
  userValue;
  constructor(private authFacade: AuthClass, private reg: RegionsService) {
    if (environment.production) {
      let settings;

      mixpanel.init("d6a7828b1e592814e6447194b89b0c7e", { debug: true });
      mixpanel.set_group("role", "customer");
      mixpanel.people.set({
        role: "customer",
      });
      try {
        this.authFacade.currentAuthenticatedUser().then(async (user) => {
          if (user) {
            console.log(user);
            await this.identifyUser(user);
            mixpanel.set_group("role", "customer");
            mixpanel.people.set({
              role: "customer",
            });
          } else {
            let machineId = localStorage.getItem("MachineId");

            if (!machineId) {
              machineId = crypto.randomUUID();
              localStorage.setItem("MachineId", machineId);
            }
            mixpanel.identify(machineId);
          }
        });
      } catch (e) {
        let machineId = localStorage.getItem("MachineId");

        if (!machineId) {
          machineId = crypto.randomUUID();
          localStorage.setItem("MachineId", machineId);
        }
        mixpanel.identify(machineId);
      }

      //subscribe to congito user updates
    }
  }

  identifyUser(user) {
    try {

      Auth.currentAuthenticatedUser().then((user) => {
        //remove any values with "ever" in them
        console.log("Identifying User", user);
        if (
          (user as any)["attributes"] != undefined &&
          (user as any)["attributes"]["sub"]
        ) {
          mixpanel.identify((user as any)["attributes"]["sub"]);

          mixpanel.set_group("role", "customer");
          mixpanel.people.set({
            region: (user as any)["attributes"]["custom:user_region"],
            role: "customer",
            name: user.getUsername(),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //addEvent
  addEvent(event: string, value: any) {
    try {
      if (environment.production) {
        //run with 100ms timeout and in try statement
        try {
          setTimeout(() => {
            if (value) {
              mixpanel.track(event, value);
            } else {
              mixpanel.track(event);
            }
          }, 100);
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
    //add blue log for dev mode
    try {
      console.log(
        `%c ${event} %c : ${JSON.stringify(value)}`,
        "background: #222; color: #e36936",
        "background: #222; color: #e36936"
      );
    } catch (error) {
      console.log(error);
    }
  }
}
