import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { BookingService } from "../../services/booking/booking.service";
import { take } from "rxjs";
import { updatePhoneNumber } from "firebase/auth";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { RegionsService } from "../../services/regions/regions.service";
import dials from "../../../assets/dialing.json";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsModalComponent implements OnInit {
  @Input() PhoneNum: string;

  constructor(
    public modalController: ModalController,
    private toast: ToastController,
    public alertController: AlertController,
    private nav: NavController,
    private active: ActivatedRoute,
    private http: HttpClient,
    private BookingSvc: BookingService,
    private region: RegionsService
  ) {}

  async ngOnInit() {
    console.log("phone number", this.PhoneNum);
  }

  close() {
    this.modalController.dismiss();
  }
}
