import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoggedInTreatUserGuard } from "./components/logged-in-treat-user.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/walkthrough",
    pathMatch: "full",
  },
  {
    path: "venue/:venue_id",
    loadChildren: () =>
      import("./treat-me-venue/treat-me-venue.module").then(
        (m) => m.TreatMeVenuePageModule
      ),
  },
  {
    path: "walkthrough",
    loadChildren: () =>
      import("./walkthrough/walkthrough.module").then(
        (m) => m.WalkthroughPageModule
      ),
  },
  {
    path: "getting-started",
    loadChildren: () =>
      import("./getting-started/getting-started.module").then(
        (m) => m.GettingStartedPageModule
      ),
  },
  {
    path: "auth/login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "auth/signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "auth/forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "app",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },

  {
    path: ":region",
    children: [
      {
        path: "venue/:venue_id",
        loadChildren: () =>
          import("./food/listing copy/food-listing.module").then(
            (m) => m.VenueListingPageModule
          ),
      },
      {
        path: "book/:event_name",
        loadChildren: () =>
          import("./events/event-details/travel-details.module").then(
            (m) => m.TravelDetailsPageModule
          ),
      },
      {
        path: "book/:booking_id/checkout",
        loadChildren: () =>
          import("./booking-checkout/booking-checkout.module").then(
            (m) => m.BookingCheckoutPageModule
          ),
      },
      {
        path: "book/:booking_id/view",
        loadChildren: () =>
          import("./confirmation-page/confirmation.module").then(
            (m) => m.ConfirmationPageModule
          ),
        canActivate: [LoggedInTreatUserGuard],
      },
      {
        path: "guest_list/:id",
        loadChildren: () =>
          import("./guest_list/confirmation.module").then(
            (m) => m.ConfirmationPageModule
          ),
      },
      {
        path: "guest_list/guest/:id",
        loadChildren: () =>
          import("./guest_confirmation/confirmation.module").then(
            (m) => m.ConfirmationPageModule
          ),
        canActivate: [LoggedInTreatUserGuard],
      },
    ],
  },
 {
    path: ":region",
    //make sub pages
    children: [
    
    ],
  },
  {
    path: ":redirect/:redirect2",
    loadChildren: () =>
      import("./page-not-found/page-not-found.module").then(
        (m) => m.PageNotFoundModule
      ),
  },


  {
    path: "**",
    redirectTo: "page-not-found",
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: "enabledBlocking",
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
