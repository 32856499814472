import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { isPlatformServer } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { environment } from "../environments/environment";
import { ReactiveFormsModule } from "@angular/forms";
import { JwtConfig, JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Amplify } from "@aws-amplify/core";
import { PipesModule } from "./pipes/pipes.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function jwtOptionsFactory(auth: AuthClass) {
  return {
    tokenGetter: () => {
      console.log("Getting Token");
      return auth
        .currentSession()
        .then((session) => {
          const idToken = session.getIdToken();
          return idToken.getJwtToken();
        })
        .catch(() => undefined);
    },
    allowedDomains: [
      "https://localhost:3000",
      "localhost:3000",
      "localhost:5000",
      "127.0.0.1",

      "127.0.0.1:3000",
      "api.au.dev.getdqd.com",
      "api.eu.dev.getdqd.com",
      "api.us.dev.getdqd.com",
      "api.au.order.getdqd.com",
      "api.eu.order.getdqd.com",
      "api.us.order.getdqd.com",
      "https://g7guey9f6i.execute-api.ap-southeast-2.amazonaws.com",
      "127.0.0.1",
      "test.dev-api.hawkencreative.com",
      "api.book.dev.getdqd.com",
      "api.book.getdqd.com",
    ],
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: "ios" }),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthClass],
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object, response: any) => {
        return () => {
          // In the server.ts we added a custom response header with information about the device requesting the app
          if (isPlatformServer(platformId)) {
            if (response && response !== null) {
              // Get custom header from the response sent from the server.ts
              const mobileDeviceHeader = response.get("mobile-device");

              // Set Ionic config mode?
            }
          }
        };
      },
      deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
      multi: true,
    },
    {
      provide: AuthClass,
      useFactory: () => {
        return Amplify.Auth;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
