import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { concatMap, filter, take } from "rxjs/operators";
import { RegionsService } from "../services/regions/regions.service";
import { ModalController, ToastController } from "@ionic/angular";

import { CognitoUser } from "@aws-amplify/auth";

import { AuthService } from "../services/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInTreatUserGuard implements CanActivate {
  constructor(private auth: AuthService, private toast: ToastController) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("Treat Me ININITINTINTITNIT");
    try {
      let res = await this.auth.RequireAuth();

      if (res == false) {
        this.toast
          .create({
            message: "You must be logged in to do that",
            duration: 3000,
            position: "top",
          })
          .then((res) => {
            res.present();
          });
      }
    } catch (e) {
      console.log(e);
      this.toast
        .create({
          message: "You must be logged in to do that",
          duration: 3000,
          position: "top",
        })
        .then((res) => {
          res.present();
        });
      return false;
    }

    return true;
  }
}
